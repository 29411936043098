import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";
import BlogFeed from "../components/blog-feed";
import CTA from "../components/cta";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import NeedAQuote from "../components/need-a-quote";
import patternHex from "../images/backrounds/pattern-hex-white.jpg";
import awsLogo from "../images/partners/aws-logo.png";
import awsWhiteLogo from "../images/partners/aws-logo-white.png";

const Index = ({ location }) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Partnerships AWS"
        description="729 Solutions is a proud partner of AWS (Amazon Web Services), implementing, managing and maintaining hosting solutions for your specific needs."
      />
      <Hero bgImage={patternHex}>
        <Hero.Inner>
          <Hero.Image
            src={awsLogo}
            srcAlt={awsWhiteLogo}
            alt="AWS Logo"
          />
          <Hero.Content>
            <Hero.Title>
              729 Solutions is proud to be part of the AWS Partner Network
            </Hero.Title>
            <Hero.Description>
              <p className="text-white text-center text-md-left">
                As a certified AWS partner, 729 Solutions can help you make the
                most of your Amazon Web Services account. We have the skills,
                people and process to build and maintain high-performance
                applications on AWS.
              </p>
            </Hero.Description>
            <Hero.CallToAction>
              <AnchorLink className="btn btn-secondary-light" to="#begin">
                What AWS Work Do You Need?
              </AnchorLink>
            </Hero.CallToAction>
          </Hero.Content>
        </Hero.Inner>
      </Hero>
      <section id="begin">
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-6">
              <div>
                <h2>horsepower your build</h2>
                <p>
                  It is getting harder and harder to find competent experienced
                  AWS developers and DevOps resources. As a certified member of
                  the Amazon Partner Network, we know the challenge. Backlogs
                  are growing. Your Developers have five projects to finish.
                  Things simply aren’t getting done. We get it and we can help.
                  If you are looking for competent resources to help you
                  complete a project click the contact form to tell us about
                  your need and get a quote.
                </p>
              </div>
              <div className="pt-4">
                <h2>cloud migrations</h2>
                <p>
                  Contemplating a move of your business applications into the
                  cloud might seem a bit scary. While most of your apps will run
                  fine, some of them won’t. Our experience as a certified Amazon
                  partner means 729 can help you evaluate your dependencies,
                  performance, and baselines so you can move to the cloud with
                  confidence.
                </p>
                <p>
                  More importantly, we have the right technical talent to
                  refactor or build cloud-first apps that will be needed. Our
                  customers expect improved performance combined with a
                  developer-friendly toolset to scale their businesses on AWS
                  and 729 will deliver on those goals.
                </p>
              </div>
              <div className="pt-4">
                <h2>build an effective ci/cd pipeline</h2>
                <p>
                  AWS has an incredible toolset to deliver code faster, safer,
                  and more collaboratively. 729 can help you pick the right
                  products and services to streamline your development cycles
                  and enable you to spin up environments and applications
                  quickly. If you want to combine other non-AWS tools 729 is
                  also a certified partner to other cloud DevOps tools including
                  Github, Chef, Redis, New Relic, Sumologic, PagerDuty
                  and more. We can help integrate all your systems into one sane
                  and practical business logic-driven process. Click the form
                  link below to schedule a time for a free review.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <h2>build cloud first apps</h2>
                <p>
                  The best running applications are ones that delight customers.
                  729 has a 15-year track record of creating exceptional web and
                  mobile applications designed to be performant, stable, and
                  achieve your revenue goals. We leverage the incredible suite
                  of AWS services and our knowledge as an AWS Certified Partner
                  to create exceptional user experiences and products that meet
                  market demands and are purpose-built for the cloud. Click the
                  button below to make a time to talk about your idea or
                  projects.
                </p>
              </div>
              <div className="pt-4">
                <h2>Integrations</h2>
                <p>
                  Every product needs to talk to other systems and integrate
                  data from multiple sources, including physical, virtual,
                  cloud-based, API, hybrid, and legacy environments.
                </p>
                <p>
                  As an integrations specialist, 729 Solutions has extensive
                  experience building scalable, fault-tolerant solutions that
                  connect your vital business tools. We are a Certified System
                  integrator to more than 30 of the fastest growing technology
                  companies.
                </p>
                <p>
                  If you are looking for competent resources to help you connect
                  all your business systems using AWS, click on the link below
                  to see how we can affordably build your integration. As a
                  trusted member of the Amazon Partner Network, we know AWS
                  inside and out and can find the right solutions for your
                  business.
                </p>
              </div>
              <div className="pt-4">
                <h2>Other AWS competencies</h2>
                <ul className="text-primary m-0 pl-3">
                  <li>
                    <span className="text-dark">Amazon CloudFront</span>
                  </li>
                  <li>
                    <span className="text-dark">Aurora</span>
                  </li>
                  <li>
                    <span className="text-dark">Lambda</span>
                  </li>
                  <li>
                    <span className="text-dark">API Gateway</span>
                  </li>
                  <li>
                    <span className="text-dark">Dynamo</span>
                  </li>
                  <li>
                    <span className="text-dark">
                      Server &amp; Database Migration
                    </span>
                  </li>
                  <li>
                    <span className="text-dark">RDS for Postgres</span>
                  </li>
                  <li>
                    <span className="text-dark">Cloudfront</span>
                  </li>
                  <li>
                    <span className="text-dark">Serverless stacks</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-6">
        <div className="container bg-light">
          <div className="row">
            <div className="col">
              <div className=" p-4">
                <h3>
                  729 solutions is a leading cloud development agency and a
                  certified member of the amazon partner network
                </h3>
                <p>
                  We create fast, stable, workflow-driven apps with continuous
                  integration and automated deployment processes. But, more than
                  anything, we build amazing user experiences.
                </p>
                <div className="text-center">
                  <CTA
                    text="Tell Us About Your Project"
                    className="btn btn-primary"
                    href="/contact-us/"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NeedAQuote dark />
      <BlogFeed containerClassname="py-6" />
    </Layout>
  );
};

export default Index;
